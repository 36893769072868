<template>
    <div v-if="isMounted" v-tab-focus.onspace="() => toggleVideo()" class="c-common-homepage-video">
        <BaseVideo
            ref="videoPlayer"
            class="c-common-homepage-video__player"
            :src="heroVideo"
            :style="videoStyles"
            muted
            autoplay
            loop
            @pause="onPause"
            @playing="onPlay"
            @error="handleVideoError"
        />
        <div v-if="content.headline" class="c-common-homepage-video__overlay"></div>
        <VideoButton
            v-if="heroVideo && !videoHasError"
            :activeButtonAction="activeButtonAction"
            :faceoutButtonText="false"
            class="c-common-homepage-video__button"
        />
        <div v-if="content.headline" class="c-common-homepage-video__heading-wrapper">
            <h1 class="c-common-homepage-video__heading">
                {{ content.headline }}
            </h1>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';

import { VIDEO_BUTTON_ACTIONS } from '~coreModules/core/js/video-constants';

import BaseVideo from '~coreModules/core/components/ui/BaseVideo.vue';
import VideoButton from '~coreModules/core/components/ui/buttons/VideoButton.vue';

export default {
    name: 'CommonHomepageVideo',
    components: {
        BaseVideo,
        VideoButton,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        activeButtonAction: VIDEO_BUTTON_ACTIONS.pause,
        isMounted: false,
        videoHasError: false,
    }),
    computed: {
        heroImage() {
            if (this.isMounted) {
                const images = get(this.content, 'heroImageUrls', { large: '', small: '' });

                return this.$mediaQueries.isSmallish ? images.small : images.large;
            }

            return '';
        },
        heroVideo() {
            if (this.isMounted) {
                const { large, small } = get(this, 'content.video', {});

                try {
                    const url = this.$mediaQueries.isSmallish ? small : large;

                    // validate URLs; if not valid, only the hero image will be displayed
                    return new URL(url).href;
                } catch (_) {
                    if (!!large || !!small) {
                        // if both URLs aren't supplied, assume user is intentionally leaving blank to show only image
                        this.$logger.debugError('Invalid video URL supplied for Shared homepage hero');
                    }
                    return '';
                }
            }

            return '';
        },
        videoStyles() {
            return {
                background: `transparent url('${this.heroImage}') 50% 50% / cover no-repeat`,
            };
        },
    },
    watch: {
        '$mediaQueries.isSmallish': function updateVideoError() {
            this.videoHasError = false;
        },
    },
    mounted() {
        this.isMounted = true;
    },
    methods: {
        onPause() {
            this.activeButtonAction = VIDEO_BUTTON_ACTIONS.play;
        },
        onPlay() {
            this.activeButtonAction = VIDEO_BUTTON_ACTIONS.pause;
        },
        toggleVideo() {
            const isPaused = this.$refs.videoPlayer.videoNode.paused;

            if (isPaused) {
                this.$refs.videoPlayer.videoNode.play();
            } else {
                this.$refs.videoPlayer.videoNode.pause();
            }
        },
        handleVideoError() {
            this.videoHasError = true;

            this.$logger.debugError('Anonymous homepage hero video failed to load');
        },
    },
};

</script>
<style lang="scss">
    $header-height-small: 48px;
    $header-height-large: 64px;
    $vertical-offset: $nu-spacer-4 + $header-height-small;
    $vertical-offset-large: $nu-spacer-4 + $header-height-large;
    $vh70: calc(70 * var(--vh));

    .c-common-homepage-video {
        width: 100%;
        height: 260px;
        margin-bottom: $nu-spacer-1;
        position: relative;
        overflow: hidden;
        border-radius: 4px;

        @include breakpoint(medium) {
            min-height: 260px;
            // not using --vh here because layout doesnt need to perfectly fit on screen
            // using --vh was causing jumpyness on iOS devices
            height: calc(#{$vh70} - #{$vertical-offset});
        }

        @include breakpoint(large) {
            width: 65%;
            min-height: 336px;
            height: calc(var(--vh100) - #{$vertical-offset-large});
            margin-bottom: 0;
        }

        &__player {
            object-fit: cover;
            object-position: center;
            min-height: 100%;
            min-width: 100%;
            height: 100%;
            width: 100%;
        }

        &__overlay {
            background-image:
                linear-gradient(to bottom, $transparent 0%, $transparent 40%, $nu-black 100%);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            opacity: .6;
        }

        &__button {
            position: absolute;
            top: $nu-spacer-2;
            right: $nu-spacer-2;
        }

        &__heading-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &__heading {
            padding: $nu-spacer-2pt5;
            color: $nu-white;
            @include heading-1;

            @include breakpoint(medium) {
                padding: $nu-spacer-6;
                @include title-1;
            }

            @include breakpoint(large) {
                padding-right: calc($nu-spacer-16 + $nu-spacer-4);
            }
        }
    }
</style>
