<template>
    <ClientMounted>
        <template #default="{ isClientMounted }">
            <i18n-t
                v-if="isClientMounted"
                :keypath="keypath"
                :tag="tag"
            >
                <template
                    v-for="(_, index) in illustrations"
                    #[`icon${index+1}`]
                    :key="`icon-${index}`"
                >
                    <Illustration
                        :illustrationSrc="illustrations[index]"
                        :illustrationSize="illustrationSize"
                        class="c-text-and-illustration__illustration"
                    />
                </template>
            </i18n-t>
        </template>
    </ClientMounted>
</template>

<script>
import ClientMounted from '~coreModules/core/components/ui/ClientMounted.vue';
import Illustration from '~coreModules/core/components/ui/Illustration.vue';

export default {
    name: 'TextAndIllustrations',
    components: {
        ClientMounted,
        Illustration,
    },
    props: {
        keypath: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            default: null,
        },
        illustrations: {
            type: Array,
            required: true,
        },
        illustrationSize: {
            type: [String, Number],
            default: '48',
        },
    },
};
</script>

<style lang="scss">
    .c-text-and-illustration {
        &__illustration {
           vertical-align: middle;
           line-height: 1;
        }
    }
</style>
