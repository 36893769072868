<i18n>
[
    "global__video--play-btn",
    "global__video--pause-btn",
    "global__video--replay-btn"
]
</i18n>
<template>
    <div class="c-video-button">
        <BaseIcon
            size="16px"
            color="primary"
            :icon="buttonIcon"
            class="c-video-button__play-button"
            :isCoreDir="true"
        />
        <span
            v-if="buttonText"
            :class="{
                'c-video-button__text--faceout': shouldFaceoutText
            }"
            class="o-text--caption u-text--color-primary c-video-button__text"
        >
            {{ $t(buttonText) }}
        </span>
    </div>
</template>

<script>
import { VIDEO_BUTTON_ACTIONS } from '~coreModules/core/js/video-constants';

export default {
    name: 'VideoButton',
    components: {},
    props: {
        activeButtonAction: {
            type: String,
            default: VIDEO_BUTTON_ACTIONS.play,
            validator: action => Object.values(VIDEO_BUTTON_ACTIONS).includes(action),
        },
        faceoutButtonText: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        buttonText() {
            return this.$t(`global__video--${this.activeButtonAction}-btn`);
        },
        buttonIcon() {
            return `video--${this.activeButtonAction}`;
        },
        shouldFaceoutText() {
            return this.buttonText && this.faceoutButtonText;
        },
    },
};
</script>

<style lang="scss">
    .c-video-button {
        $this: &;

        display: flex;
        height: 32px;
        width: auto;
        padding: $nu-spacer-1;
        justify-content: flex-start;
        align-items: center;
        background-color: $nu-white;
        border-radius: 8px;
        user-select: none;
        overflow: hidden;
        cursor: pointer;

        &__text {
            overflow: hidden;
            max-width: 0;
            transition: all .2s ease;
            display: inline-block;
            white-space: nowrap;
            opacity: 0;

            &--faceout {
                max-width: 100px;
                padding-left: $nu-spacer-1;
                opacity: 1;
            }

            @media(hover: hover) and (pointer: fine) {
                #{$this}:hover & {
                    max-width: 100px;
                    padding-left: $nu-spacer-1;
                    opacity: 1;
                }
            }
        }
    }
</style>
