<script>
import { h } from 'vue';
import { merge } from 'lodash-es';

/*
    *******************
    *** Usage Notes ***
    *******************

  * HTML <video> Attributes:
    The BaseVideo component wraps a single native <video> element. Attributes which are valid on the <video> element are
    also valid on this component, including classes and Vue listeners:

        <BaseVideo
            ref="exampleVid"
            :src="'https://www.w3schools.com/html/mov_bbb.mp4'"
            controls
            muted
            autoplay
        />

  * Using the HTMLMediaElement API:
    In order to use HTMLMediaElement methods/properties, the <video> is accessible in the parent as videoNode, e.g.

        // can be referenced in a template to programmatically pause the video
        pauseVideo() {
            this.$refs.exampleVid.videoNode.pause();
        }

  * Entering fullscreen:
    There is a method requestFullscreen() on the BaseVideo.vue instance which exposes fullscreen functionality in a
    cross-browser way. Note that this method belongs to the component itself, not on component.videoNode

        launchFullscreen() {
            // the method returns a promise, as requesting fullscreen is technically an async operation
            this.$refs.exampleVid.requestFullscreen()
                .catch(error => alert(`Your browser doesn't support fullscreen videos because: ${error}`));
        }
 */
export default {
    name: 'BaseVideo',
    props: {
        src: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'video/mp4',
            validator(type) {
                return type.indexOf('video/') === 0;
            },
        },
        minimizeBehavior: {
            type: String,
            default: 'none',
            validator(behavior) {
                // when fullscreen is exited, the video can:
                // - 'reset': be reset to its original state. Useful for cases where the video is only viewable
                //            fullscreen, and should not continue playing in the background when minimized. This option
                //            also removes the Media Session API notification on Android devices
                // - 'pause': pause playback, but keep progress and android Media Session notification
                // - 'none': video keeps playing and android Media Session notification is retained
                return ['reset', 'pause', 'none'].includes(behavior);
            },
        },
    },
    emits: ['error', 'suspend'],
    data() {
        return {
            isMounted: false,
            videoNode: null,
        };
    },
    watch: {
        src: function repaintOnSrcChange() {
            if (this.isMounted) {
                // force video to repaint when the src changes, since modifying the src alone doesn't do this
                this.videoNode?.load();
            }
        },
    },
    mounted() {
        this.isMounted = true;

        this.$nextTick(() => {

            this.videoNode = this.$refs.video;

            document.addEventListener('fullscreenchange', this.fullscreenChangeHandler);
            document.addEventListener('webkitfullscreenchange', this.fullscreenChangeHandler);
            document.addEventListener('MSFullscreenChange', this.fullscreenChangeHandler);
            this.videoNode?.addEventListener('webkitendfullscreen', this.fullscreenChangeHandler);
        });
    },
    unmounted() {
        document.removeEventListener('fullscreenchange', this.fullscreenChangeHandler);
        document.removeEventListener('MSFullscreenChange', this.fullscreenChangeHandler);
        document.removeEventListener('webkitfullscreenchange', this.fullscreenChangeHandler);
        this.videoNode?.removeEventListener('webkitendfullscreen', this.fullscreenChangeHandler);
    },
    methods: {
        requestFullscreen() {
            // the DOM Fullscreen API is supposed to be async, though not all browsers support this (i.e. some don't
            // return a promise); this method handles both cases and exposes the functionality as a Promise
            let requestResult;

            return new Promise((resolve, reject) => {
                if (this.videoNode.requestFullscreen) {
                    requestResult = this.videoNode.requestFullscreen();
                } else if (this.videoNode.msRequestFullscreen) {
                    // MS Edge nonstandard syntax
                    requestResult = this.videoNode.msRequestFullscreen();
                } else if (this.videoNode.webkitRequestFullscreen) {
                    // desktop + iPad safari nonstandard syntax
                    requestResult = this.videoNode.webkitRequestFullscreen();
                } else if (this.videoNode.webkitEnterFullscreen) {
                    // iPhone safari nonstandard syntax
                    requestResult = this.videoNode.webkitEnterFullscreen();
                } else {
                    requestResult = Promise.reject('fullscreen not supported');
                }

                Promise.resolve(requestResult)
                    .then(() => resolve())
                    .catch(err => reject(err));
            });
        },
        fullscreenChangeHandler() {
            if (['pause', 'reset'].includes(this.minimizeBehavior)) {
                // detect fullscreen video for different browsers
                const isFullscreen =
                    !!document.fullscreenElement ||
                    !!document.webkitFullscreenElement ||
                    !!document.msFullscreenElement;

                if (!isFullscreen) {
                    if (this.minimizeBehavior === 'reset') {
                        this.videoNode.load();
                    } else if (this.minimizeBehavior === 'pause') {
                        this.videoNode.pause();
                    }
                }
            }
        },
    },
    render() {
        if (!this.isMounted) {
            return null;
        }

        const self = this;

        const defaultData = {
            ref: 'video',
            class: 'c-video',
            playsinline: 'playsinline', // required for iOS autoplay
            disableRemotePlayback: 'disableRemotePlayback', // hide casting button by default

        };

        return h(
            'video',
            merge({}, defaultData, {
                ...self.$attrs,
                onLoadstart() {
                    // address Chrome issue where $attrs.muted='muted' doesn't properly set HTMLVideoElement.muted,
                    // which prevents autoplay
                    if ('muted' in self.$attrs) {
                        self.videoNode.muted = true;
                    }
                },
            }),
            [
                h('source', {
                    src: self.src,
                    type: self.type,
                    onError() {
                        self.$emit('error');
                    },
                    onSuspend() {
                        self.$emit('suspend');
                    },
                }),
            ],
        );
    },
};
</script>

<style lang="scss">
    .c-video {
        display: block;
    }
</style>
