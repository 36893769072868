<template>
    <div class="o-row c-common-homepage-brand-story">
        <div class="o-extra-small--12 o-medium--10 o-medium--offset-1 u-text--center">
            <div class="c-common-homepage-brand-story__content-wrap">
                <div class="c-common-homepage-brand-story__content u-spacer--5">
                    <TextAndIllustrations
                        :illustrations="illustrations"
                        :illustrationSize="illustrationSize"
                        keypath="sharedHomepage__brandStoryRow--textAndIllustrations"
                    />
                </div>
                <div class="c-common-homepage-brand-story__buttons">
                    <BaseButton
                        variant="secondary"
                        @click="goToExternalApp('rent')"
                    >
                        {{ $t('sharedHomepage__leftButton--label') }}
                    </BaseButton>
                    <BaseButton
                        variant="secondary"
                        @click="goToExternalApp('thrift')"
                    >
                        {{ $t('sharedHomepage__rightButton--label') }}
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextAndIllustrations from '~coreModules/contentful/components/TextAndIllustrations.vue';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

import IllustrationPolaroid from '~coreImages/illustration__polaroid.png';
import IllustrationFlowers from '~coreImages/illustration__flowers.png';
import IllustrationItem from '~coreImages/illustration__item.png';
import IllustrationFriends from '~coreImages/illustration__friends.png';
import IllustrationWateringCan from '~coreImages/illustration__watering-can.png';
import IllustrationMoney from '~coreImages/illustration__money.png';

export default {
    name: 'CommonHomepageBrandStory',
    components: {
        TextAndIllustrations,
        BaseButton,
    },
    emits: ['external-app-button-clicked'],
    computed: {
        illustrations() {
            return [
                IllustrationPolaroid,
                IllustrationItem,
                IllustrationFlowers,
                IllustrationFriends,
                IllustrationWateringCan,
                IllustrationMoney,
            ];
        },
        illustrationSize() {
            return this.$mediaQueries.isSmallish ? '32' : '48';
        },
    },
    methods: {
        goToExternalApp(appName) {
            if (appName) {
                const link = `${this.$userState.baseUrl}/${appName}`;

                this.$emit('external-app-button-clicked', {
                    target: { href: link },
                });

                window.location.href = link;
            }
        },
    },
};
</script>

<style lang="scss">
    .c-common-homepage-brand-story {
        width: 100%;

        &__content-wrap {
            padding: $nu-spacer-20 0;

            @include breakpoint(medium) {
                padding: 256px 0;
            }
        }

        &__content {
            @include heading-2;

            @include breakpoint(large) {
                @include title-4;
                line-height: 48px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            width: 100%;

            button {
                width: 167px;

                @include breakpoint(large) {
                    width: 200px;
                }
            }

            button:first-child {
                margin-right: $nu-spacer-1;

                @include breakpoint(medium) {
                    margin-right: $nu-spacer-2;
                }
            }
        }
    }
</style>
