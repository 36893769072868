<script>
export default {
    name: 'ClientMounted',
    data: () => ({
        isClientMounted: false,
    }),
    mounted() {
        if (process.env.VUE_ENV === 'client') {
            this.isClientMounted = true;
        }
    },
    render() {
        return this.$slots.default({
            isClientMounted: this.isClientMounted,
        });
    },
};

</script>
