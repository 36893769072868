// eslint-disable-next-line import/prefer-default-export
export const COMMON_HOME_MODULE_IDS = {
    hero: 'HERO',
    topCallout: 'TOP_CALLOUT',
    bottomCallout: 'BOTTOM_CALLOUT',
    brandStoryRow: 'BRANDSTORY_ROW',
    anonymousHero: 'ANONYMOUS_HOMEPAGE_HERO',
    contentRow1: 'ANONYMOUS_HOMEPAGE_CONTENT_ROW_1',
    brands: 'ANONYMOUS_HOMEPAGE_BRANDS',
    contentRow2: 'ANONYMOUS_HOMEPAGE_CONTENT_ROW_2',
    socialGrid: 'ANONYMOUS_HOMEPAGE_SOCIAL_GRID',
    recTrayRow: 'ANONYMOUS_HOMEPAGE_REC_TRAY',
    thrift: 'ANONYMOUS_HOMEPAGE_THRIFT',
};
