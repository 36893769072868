<template>
    <div v-if="content" class="c-common-anonymous-homepage">
        <CommonHomepageVideo
            v-if="content.heroRow"
            :content="content.heroRow"
            @click="onModuleClick($event, moduleIds.hero)"
        />
        <CommonHomepageCallouts
            :rentalContent="content.rentalCallout"
            :thriftContent="content.thriftCallout"
            @external-app-button-clicked="onModuleClick($event)"
        />
        <CommonHomepageBrandStory
            v-if="content.shouldDisplayBrandStoryRow"
            @external-app-button-clicked="onModuleClick($event, moduleIds.brandStoryRow)"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { getClickEventHref } from '~coreModules/core/js/utils';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { CONTENTFUL_CONTENT_CLICKED } from '~coreModules/core/js/global-event-constants';

import { COMMON_HOME_MODULE_IDS } from '~routes/homepage/js/homepage-constants';

import CommonHomepageCallouts from '~routes/homepage/components/CommonHomepageCallouts.vue';
import CommonHomepageVideo from '~routes/homepage/components/CommonHomepageVideo.vue';
import CommonHomepageBrandStory from '~routes/homepage/components/CommonHomepageBrandStory.vue';

export default {
    name: 'CommonAnonymousHomepage',
    components: {
        CommonHomepageCallouts,
        CommonHomepageVideo,
        CommonHomepageBrandStory,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            moduleIds: COMMON_HOME_MODULE_IDS,
        };
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        onModuleClick(event, moduleId) {
            const clickEventHref = getClickEventHref(event);
            const contentModuleId = event.moduleId ? event.moduleId : moduleId;

            this.trackGlobalEvent({
                type: CONTENTFUL_CONTENT_CLICKED,
                data: {
                    destination: clickEventHref,
                    contentModuleId,
                    contentTitle: this.contentTitle,
                },
            });
        },
    },
};

</script>

<style lang="scss">
.c-common-anonymous-homepage {
    padding: $nu-spacer-1;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(large) {
        padding: $nu-spacer-2;
    }
}
</style>
