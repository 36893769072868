<template>
    <div class="c-common-homepage-callouts">
        <div
            class="c-common-homepage-callouts__box c-common-homepage-callouts__box--rental"
        >
            <div
                v-if="rentalContent"
                v-tab-focus.onspace="() => goToExternalApp('rent', moduleIds.topCallout)"
                :style="rentalBackgroundStyle"
                class="c-common-homepage-callouts__box-content c-common-homepage-callouts__box-content--rental"
            >
                <div
                    :class="`c-common-homepage-callouts__box-content-inner
                        c-common-homepage-callouts__box-content-inner--rental
                        o-flex-vertical-center o-flex-horizontal-center`"
                >
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        alt="Logo"
                        class="c-common-homepage-callouts__logo"
                        v-html="dynamicSVGRequire('logo--rent.svg', $logger, true)"
                    ></div>
                    <!-- eslint-enable -->
                    <p v-if="rentalContent.label" class="o-text--body-copy u-spacer--2">
                        {{ rentalContent.label }}
                    </p>
                    <BaseLink
                        v-if="rentalContent.ctaText && rentalContent.ctaUrl"
                        :to="rentalContent.ctaUrl"
                        class="o-text--caption"
                    >
                        {{ rentalContent.ctaText }}
                        <BaseIcon
                            icon="global--right-arrow"
                            size="16px"
                            color="primary"
                            class="c-common-homepage-callouts__link-arrow"
                            :isCoreDir="true"
                        />
                    </BaseLink>
                </div>
            </div>
        </div>
        <div
            class="c-common-homepage-callouts__box c-common-homepage-callouts__box--thrift"
        >
            <div
                v-if="thriftContent"
                v-tab-focus.onspace="() => goToExternalApp('thrift', moduleIds.bottomCallout)"
                :style="thriftBackgroundStyle"
                :class="`c-common-homepage-callouts__box-content
                o-flex-vertical-center o-flex-horizontal-center`"
            >
                <div
                    :class="`c-common-homepage-callouts__box-content-inner
                        c-common-homepage-callouts__box-content-inner--thrift
                        o-flex-vertical-center o-flex-horizontal-center`"
                >
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        alt="Logo"
                        class="c-common-homepage-callouts__logo c-common-homepage-callouts__logo--thrift"
                        v-html="dynamicSVGRequire('logo--thrift.svg', $logger, true)"
                    ></div>
                    <!-- eslint-enable -->
                    <p v-if="thriftContent.label" class="o-text--body-copy u-spacer--2">
                        {{ thriftContent.label }}
                    </p>
                    <BaseLink
                        v-if="thriftContent.ctaUrl && thriftContent.ctaText"
                        :to="thriftContent.ctaUrl"
                        :class="`c-common-homepage-callouts__link
                            c-common-homepage-callouts__link--thrift o-text--caption`"
                    >
                        {{ thriftContent.ctaText }}
                        <BaseIcon
                            icon="global--right-arrow"
                            size="16px"
                            color="secondary"
                            class="c-common-homepage-callouts__link-arrow"
                            :isCoreDir="true"
                        />
                    </BaseLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { dynamicSVGRequire } from '~coreModules/core/js/svg-utils';

import { COMMON_HOME_MODULE_IDS } from '~routes/homepage/js/homepage-constants';

export default {
    name: 'CommonHomepageCallouts',
    components: {},
    props: {
        rentalContent: {
            type: Object,
            required: true,
        },
        thriftContent: {
            type: Object,
            required: true,
        },
    },
    emits: ['external-app-button-clicked'],
    data() {
        return {
            dynamicSVGRequire,
            moduleIds: COMMON_HOME_MODULE_IDS,
        };
    },
    computed: {
        rentalBackgroundStyle() {
            return {
                backgroundImage: `url('${this.rentalContent.hoverImage}')`,
            };
        },
        thriftBackgroundStyle() {
            return {
                backgroundImage: `url('${this.thriftContent.hoverImage}')`,
            };
        },
    },
    methods: {
        goToExternalApp(appName, moduleId) {
            if (appName) {
                const link = `${this.$userState.baseUrl}/${appName}`;

                this.$emit('external-app-button-clicked', {
                    target: { href: link },
                    moduleId,
                });

                window.location.href = link;
            }
        },
    },
};

</script>
<style lang="scss">
    $header-height-small: 48px;
    $header-height-large: 64px;
    $vertical-offset: $nu-spacer-4 + $header-height-small;
    $vertical-offset-large: $nu-spacer-4 + $header-height-large;
    $vh30: calc(30 * var(--vh));

    .c-common-homepage-callouts {
        $this: &;

        width: 100%;
        min-height: 168px;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
            height: calc(#{$vh30} - #{$nu-spacer-2});
        }

        @include breakpoint(large) {
            width: 35%;
            height: calc(var(--vh100) - #{$vertical-offset-large});
            flex-wrap: wrap;
            padding-left: $nu-spacer-2;
        }

        &__box {
            width: 100%;
            height: auto;
            min-height: 168px;

            @include breakpoint(medium) {
                width: 50%;
                height: calc(#{$vh30} - #{$nu-spacer-2});
            }

            @include breakpoint(large) {
                width: 100%;
                height: 50%;
            }

            &--rental {
                margin-bottom: $nu-spacer-1;

                @include breakpoint(medium) {
                    margin-right: $nu-spacer-1;
                    margin-bottom: 0;
                }

                @include breakpoint(large) {
                    margin-right: 0;
                    padding-bottom: $nu-spacer-2;
                }
            }
        }

        &__box-content {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            text-align: center;
            position: relative;
            flex-direction: column;
            background-size: cover;
            cursor: pointer;
        }

        &__box-content-inner {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            flex-direction: column;
            transition: all .5s ease;
            padding: $nu-spacer-5 $nu-spacer-2;

            @include breakpoint(medium) {
                padding: 0 $nu-spacer-2;
            }

            @include breakpoint(large) {
                padding: 0 $nu-spacer-3;
            }

            @include breakpoint(extra-large) {
                padding: 0 $nu-spacer-6;
            }

            &--rental {
                background-color: $nu-secondary--100;
                color: $nu-primary;

                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgba($nu-secondary--100, .7);
                    }
                }
            }

            &--thrift {
                background-color: $nu-primary;
                color: $nu-secondary--100;

                @media(hover: hover) and (pointer: fine) {
                    &:hover {
                        background-color: rgba($nu-primary, .7);
                    }
                }
            }
        }

        &__logo {
            width: 122px;
            height: 24px;
            fill: $nu-primary;
            color:  $nu-primary;
            margin-bottom: $nu-spacer-1;

            @include breakpoint(medium) {
                width: 167px;
                height: 32px;
            }

            svg {
                width: 100%;
                max-width: 100%;
            }

            &--thrift {
                fill: $nu-secondary--100;
                color: $nu-secondary--100;
            }
        }

        &__link {
            &--thrift {
                color: $nu-secondary--100;
            }
        }

        &__link-arrow {
            max-width: 0px;
            padding-top: $nu-spacer-0pt5;
            padding-left: $nu-spacer-0pt5;

            @media(hover: hover) and (pointer: fine) {
                #{$this}__box-content-inner:hover & {
                    max-width: 16px;
                }
            }
        }
    }
</style>
